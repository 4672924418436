<template>
  <div>
    <div style="padding: 10px 50px">
      <h3 style="text-align: left; color: #696969">
        {{ $store.state.common.sScreenTitle }}
      </h3>
      <hr />
    </div>

    <div v-show="$store.state.common.sIsLoading">Loading...</div>
    <div v-show="!$store.state.common.sIsLoading">
      <div class="uk-flex uk-flex-center">
        <div class="uk-container">
          <div class="uk-text-center" uk-grid>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.company.sLblComName
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <input class="uk-input" type="text" v-model="vComName" />
              </div>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.company.sLblComKana
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <input class="uk-input" type="text" v-model="vComKana" />
              </div>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.company.sLblComZip
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <input class="uk-input" type="text" v-model="vComZip" />
              </div>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.company.sLblComAddress
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <input class="uk-input" type="text" v-model="vComAddress" />
              </div>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.company.sLblComTel
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <input class="uk-input" type="text" v-model="vComTel" />
              </div>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.company.sLblComManagerName
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <input class="uk-input" type="text" v-model="vComManagerName" />
              </div>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.company.sLblComMailAddress
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <input class="uk-input" type="text" v-model="vComMailAddress" />
              </div>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.company.sLblContractType
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <select
                  class="uk-select"
                  v-model="vContractType"
                  id="vContractType"
                  @change="doSetSelectedLabel('vContractType')"
                >
                  <option
                    v-for="nDat in vNameListData"
                    :value="nDat.detail_name_id"
                    :key="nDat.detail_name_id"
                  >
                    {{ nDat.detail_display_name1 }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div style="padding: 10px">
      <div style="width: 100%; text-align: right; margin-bottom: 10px">
        <button
          type="button"
          class="operate-button"
          style="
            width: 10%;
            line-height: 3;
            background-color: #696969;
            border-radius: 10px;
            border: solid 1px #696969;
            color: #ffffff;
          "
          @click="doConfirmUpdate(vModalId)"
        >
          更新
        </button>
      </div>
    </div>

    <!-- 何かメッセージ出す用 -->
    <p>
      <b>{{ vMessage }}</b>
    </p>

    <!-- 更新時モーダル -->
    <div :id="vModalId" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h3 class="uk-modal-title" style="color: #696969">確認</h3>
        </div>
        <div class="uk-modal-body" uk-overflow-auto>
          <p style="color: #696969">更新しますか？</p>
          <div style="padding: 10px">
            <div style="width: 100%; text-align: center; margin-bottom: 10px">
              <table class="sc-table" border="1">
                <tr>
                  <th class="sc-th">{{ $store.state.company.sLblComName }}</th>
                  <td>{{ vComName }}</td>
                </tr>
                <tr>
                  <th class="sc-th">{{ $store.state.company.sLblComKana }}</th>
                  <td>{{ vComKana }}</td>
                </tr>
                <tr>
                  <th class="sc-th">{{ $store.state.company.sLblComZip }}</th>
                  <td>{{ vComZip }}</td>
                </tr>
                <tr>
                  <th class="sc-th">
                    {{ $store.state.company.sLblComAddress }}
                  </th>
                  <td>{{ vComAddress }}</td>
                </tr>
                <tr>
                  <th class="sc-th">{{ $store.state.company.sLblComTel }}</th>
                  <td>{{ vComTel }}</td>
                </tr>
                <tr>
                  <th class="sc-th">
                    {{ $store.state.company.sLblComManagerName }}
                  </th>
                  <td>{{ vComManagerName }}</td>
                </tr>
                <tr>
                  <th class="sc-th">
                    {{ $store.state.company.sLblComMailAddress }}
                  </th>
                  <td>{{ vComMailAddress }}</td>
                </tr>
                <tr>
                  <th class="sc-th">
                    {{ $store.state.company.sLblContractType }}
                  </th>
                  <td>{{ vContractTypeName }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <div style="padding: 10px">
            <div
              style="
                width: 100%;
                text-align: right;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
            >
              <button
                type="button"
                class="operate-button"
                style="
                  width: 20%;
                  line-height: 3;
                  background-color: #ffffff;
                  border-radius: 10px;
                  border: solid 1px #696969;
                  color: #696969;
                "
                @click="doHideModal"
              >
                キャンセル
              </button>
              <button
                :disabled="$store.state.common.sServerProcessing"
                type="button"
                class="operate-button"
                style="
                  width: 20%;
                  line-height: 3;
                  background-color: #696969;
                  border-radius: 10px;
                  border: solid 1px #696969;
                  color: #ffffff;
                "
                @click="doUpdateInfo"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constant from "@/constant.js";
import UIkit from "uikit";

export default {
  data() {
    return {
      vComId: this.$store.state.common.sComId,
      vComName: "",
      vComKana: "",
      vComZip: "",
      vComAddress: "",
      vComTel: "",
      vComManagerName: "",
      vComMailAddress: "",
      vContractType: "",
      vContractTypeName: "",
      vRegiStatus: "",
      vModalId: "modal-confirm-customer-company",
      vNameListData: [],
      vMessage: "",
    };
  },
  created() {
    // 画面読み込み中・・・
    this.$store.state.common.sIsLoading = true;
    // ログイン状態判定
    this.doAuthLoginInfo();
    if (!this.$store.state.common.sIsLogined) {
      this.$router.push("/login");
    }
    // 画面タイトル
    this.$store.commit("common/doSetScreenTitle", {
      vScreenTitle: "クライアント/企業情報",
    });

    // DB参照処理
    if (this.vComId) {
      this.vMessage = "";
      this.$axios
        .get(constant.cServerRoute.customer.company, {
          params: {
            pComId: this.vComId,
          },
        })
        .then(
          function (response) {
            console.log(response);
            // 企業情報参照処理判定
            if (
              response.data.return_result ==
              constant.cServerReturnResult.success
            ) {
              // 企業情報を保持する
              if (response.data.return_data_com) {
                this.vComName = response.data.return_data_com.com_name;
                this.vComKana = response.data.return_data_com.com_kana;
                this.vComZip = response.data.return_data_com.com_zip;
                this.vComAddress = response.data.return_data_com.com_address;
                this.vComTel = response.data.return_data_com.com_tel;
                this.vComManagerName =
                  response.data.return_data_com.com_manager_name;
                this.vComMailAddress =
                  response.data.return_data_com.com_mail_address;
                this.vContractType =
                  response.data.return_data_com.contract_type;
                this.vRegiStatus = response.data.return_data_com.regi_status;
              }
              // 表示名リストを保持する
              if (response.data.return_data_name) {
                this.vNameListData = response.data.return_data_name;
              }
            } else {
              this.vMessage = response.data.return_message;
            }
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
          this.vMessage = error;
        });
    }
  },
  // 画面読み込み終わった？
  mounted() {
    this.$store.state.common.sIsLoading = false;
  },
  methods: {
    // DB更新処理
    doUpdateInfo() {
      this.vMessage = "";
      // 入力チェック
      this.$store.state.common.sAbleUpdate = true;
      this.doCheckValueBeforeUpdate();
      if (!this.$store.state.common.sAbleUpdate) {
        // モーダル閉じる
        this.doHideModal();
        return;
      }
      // ボタン制御
      this.$store.commit("common/doStartServerProcess");
      // DB更新
      this.$axios
        .post(constant.cServerRoute.customer.company, {
          pComId: this.vComId,
          pComName: this.vComName,
          pComKana: this.vComKana,
          pComZip: this.vComZip,
          pComAddress: this.vComAddress,
          pComTel: this.vComTel,
          pComManagerName: this.vComManagerName,
          pComMailAddress: this.vComMailAddress,
          pContractType: this.vContractType,
        })
        .then(
          function (response) {
            console.log(response);
            // 企業情報更新処理判定
            if (
              response.data.return_result ==
              constant.cServerReturnResult.success
            ) {
              // ユーザー情報を変更する
              this.$store.commit("common/doUpdateCompany", {
                vComName: response.data.return_data_com.com_name,
                vMailAddress: response.data.return_data_com.com_mail_address,
                vUserName: response.data.return_data_com.com_manager_name,
                vRegiStatus: response.data.return_data_com.regi_status,
              });
              // 更新メッセージ表示
              this.$store.commit("common/doNotificationUpdate", {
                vMode: constant.cNotificationUpdateMode.success,
              });
              // モーダル閉じる
              this.doHideModal();
              // ボタン制御
              this.$store.commit("common/doEndServerProcess");
            } else {
              // モーダル閉じる
              this.doHideModal();
              // ボタン制御
              this.$store.commit("common/doEndServerProcess");
              this.vMessage = response.data.return_message;
            }
          }.bind(this)
        )
        .catch(function (error) {
          // 更新メッセージ表示
          this.$store.commit("common/doNotificationUpdate", {
            vMode: constant.cNotificationUpdateMode.failure,
          });
          // モーダル閉じる
          this.doHideModal();
          // ボタン制御
          this.$store.commit("common/doEndServerProcess");
          console.log(error);
          this.vMessage = error;
        });
    },
    // 更新前値チェック処理
    doCheckValueBeforeUpdate() {
      // 企業名
      this.$store.commit("common/doCheckInput", {
        vVal: this.vComName,
        vLabel: this.$store.state.company.sLblComName,
      });
      // 企業名カナ
      this.$store.commit("common/doCheckInput", {
        vVal: this.vComKana,
        vLabel: this.$store.state.company.sLblComKana,
      });
      // 郵便番号
      this.$store.commit("common/doCheckInput", {
        vVal: this.vComZip,
        vLabel: this.$store.state.company.sLblComZip,
      });
      // 住所
      this.$store.commit("common/doCheckInput", {
        vVal: this.vComAddress,
        vLabel: this.$store.state.company.sLblComAddress,
      });
      // 電話番号
      this.$store.commit("common/doCheckInput", {
        vVal: this.vComTel,
        vLabel: this.$store.state.company.sLblComTel,
      });
      // 担当者
      this.$store.commit("common/doCheckInput", {
        vVal: this.vComManagerName,
        vLabel: this.$store.state.company.sLblComManagerName,
      });
      // メールアドレス
      this.$store.commit("common/doCheckInput", {
        vVal: this.vComMailAddress,
        vLabel: this.$store.state.company.sLblComMailAddress,
      });
      // 契約形態
      this.$store.commit("common/doCheckSelect", {
        vVal: this.vContractType,
        vLabel: this.$store.state.company.sLblContractType,
      });
    },
    // DB更新前確認処理
    doConfirmUpdate() {
      UIkit.modal("#" + this.vModalId).show();
    },
    // モーダル閉じる
    doHideModal() {
      UIkit.modal("#" + this.vModalId).hide();
    },
    // selectオブジェクトの表示ラベルをセットする
    doSetSelectedLabel(objId) {
      var obj = document.getElementById(objId);
      if (obj) {
        if (obj.selectedIndex > -1) {
          var txt = obj.options[obj.selectedIndex].text;
          switch (objId) {
            case "vContractType":
              // 契約形態
              this.vContractTypeName = txt;
              break;
          }
        }
      }
    },
    // 戻る処理
    doBackToHome() {
      this.$router.push("/customer-home");
    },
    // ID,パスワード照合
    doAuthLoginInfo() {
      if (sessionStorage.getItem("storageId")) {
        let createHash = require("sha256-uint8array").createHash;
        let tmp = createHash()
          .update(sessionStorage.getItem("storagePw"))
          .digest("hex");
        this.$axios
          .post(constant.cServerRoute.common.login, {
            pLoginId: sessionStorage.getItem("storageId"),
            pLoginPw: tmp,
          })
          .then(
            function (response) {
              console.log(response);
              if (
                response.data.return_result !=
                constant.cServerReturnResult.success
              ) {
                this.$store.state.common.sIsLogined = false;
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
            this.$store.state.common.sIsLogined = false;
          });
      }
    },
  },
  // DOM生成後に動く、らしい
  updated() {
    // selectオブジェクトの表示ラベルを保持しておく
    if (this.vContractType) {
      this.doSetSelectedLabel("vContractType");
    }
  },
  // ページを離れる
  beforeRouteLeave() {
    UIkit.modal("#" + this.vModalId).$destroy(true);
  },
};
</script>
